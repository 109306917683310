<template>
  <div class="login">
    <v-container>
      <v-row class="text-center primary--text">
        <v-col cols="12">
          <p class="text-h4">
            <strong style="font-family: Kelly Slab !important"
              >Boleto de parcela.</strong
            >
          </p>

          <p class="text-h5" style="font-family: Kelly Slab !important">
            Informe sua senha para ver o boleto
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title class="text-center"></v-toolbar-title>

              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  label="Entre com sua senha"
                  v-model="login"
                  prepend-icon="mdi-account"
                  type="text"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" block @click="enviar">Ver Boleto</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { api } from "@/conf/api";

export default {
  name: "AlgarDemais",
  data() {
    return {
      login: "",
    };
  },
  methods: {
    unicoValor(value, index, self) {
      return self.indexOf(value.contrato) === index;
    },
    async enviar() {
      // achar o boleto

      this.$store.commit("zeraMessage");
      let cpf = this.login.replace(/\D/gi, "");
      if (!cpf) {
        this.$store.commit("showMessage", {
          text: "Não Achamos Boleto com essa Senha",
          color: "warning",
          show: true,
        });
        this.login = "";
        return;
      }
      await this.$store.dispatch("insereCPF", cpf);
      await this.$store.dispatch("marcaCredorAtual", "Algar");
      //console.log(cpf);
      let res = await api.get("algar/consultardivida/" + cpf);

      if (
        !res.data.objResultado ||
        res.data.objResultado.XML.RETORNO._text != "00"
      ) {
        this.$store.commit("showMessage", {
          text: "Não Achamos Boleto com essa Senha",
          color: "warning",
          show: true,
        });
        this.login = "";
        return;
      }

      let parcelas;
      // console.log(res.data.objResultado.XML);

      if (Array.isArray(res.data.objResultado.XML.DIVIDA.PREST)) {
        parcelas = res.data.objResultado.XML.DIVIDA.PREST.filter((v) => {
          return v.PRODUTO._text == "PARC";
        });
      } else {
        if (res.data.objResultado.XML.DIVIDA.PREST.PRODUTO._text == "PARC") {
          parcelas = [res.data.objResultado.XML.DIVIDA.PREST];
        }
      }

      if (parcelas.length == 0) {
        this.$store.commit("showMessage", {
          text: "Não Achamos Boleto com essa Senha",
          color: "warning",
          show: true,
        });
        this.login = "";
        return;
      }

      await this.$store.dispatch("guardaOpercaoBoletos", parcelas);
      //console.log(parcelas);
      this.$router.push("/demais2");

      /*

      let parcela = parcelas[0];
      let acordo = parcela.CONTRATO._text;
      let venc = parcela.VENCIMENTO._text;
      let parc = parcela.NUMPREST._text;

      let strXML = pegarBoletoXML(cpf, acordo, venc, parc);
      // console.log("strBoleto", strXML.objXML);

      let res2 = await api.post("algar/emitirboleto", { str: strXML.objXML });

      console.log(res2.data);



      let todosBoletosPossiveis = res.data.dados.filter(function(a) {
        return (
          !this[JSON.stringify(a.contrato)] &&
          (this[JSON.stringify(a.contrato)] = true)
        );
      }, Object.create(null));

      let boletosDisponiveis = [];

      todosBoletosPossiveis.forEach((v) => {
        let obj = JSON.parse(v.xml_volta);
        v.js = XML2JS(obj.EmitirBoletoResult);
        //console.log(v.js);
        v.venc = v.js.XML.DADOBOLETO.DATVENCI._text;
        v.valor = v.js.XML.DADOBOLETO.VALPAGO._text.replace(",", ".");
        if (v.js.XML.RETORNO._text == "00") {
          // return this.$router.push("ErroPage");
          //} else {
          let datav = new Date(v.venc);
          let datahoje = new Date();
          if (datav > datahoje) {
            console.log(datav > datahoje);
            boletosDisponiveis.push(v);
          }
        }
      });

      if (boletosDisponiveis.length == 0) {
        return this.$router.push("ErroPage");
      }

      this.$store.dispatch("guardaOpercaoBoletos", boletosDisponiveis);

      this.$router.push("Operacao2");
      */
    },
  },
  mounted() {
    this.$store.dispatch("setMostraBotoes", { imprimir: false, voltar: false });
  },
};
</script>

<style></style>
